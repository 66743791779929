import React from 'react'

export function CloseIcon() {
    return (
        <svg width="40px" height="41px" viewBox="0 0 40 41" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="sign-up" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Kaupa-01" transform="translate(-1844.000000, -42.000000)" fill="#1F5CB8" fillRule="nonzero">
                    <path d="M1882.48115,42.0859121 L1883.91409,43.4811455 L1865.39491,62.4999121 L1883.91409,81.5188545 L1882.48115,82.9140879 L1863.99991,63.9329121 L1845.51885,82.9140879 L1844.08591,81.5188545 L1862.60391,62.4999121 L1844.08591,43.4811455 L1845.51885,42.0859121 L1863.99991,61.0659121 L1882.48115,42.0859121 Z" id="Combined-Shape"></path>
                </g>
            </g>
        </svg>
    )
}



