import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { STEP_LOGIN } from '../../constants'
import { register } from '../../slices/auth'
import { setActivePane } from '../../slices/modal'

export default function Register(props) {

    const [hasRegistered, setHasRegistered] = useState(false)

    const dispatch = useDispatch()
    const { registrationErrors } = useSelector((state) => state.auth);

    const email = React.createRef()
    const password1 = React.createRef()
    const password2 = React.createRef()
    const first_name = React.createRef()
    const last_name = React.createRef()
    const ssn = React.createRef()

    let registrationErrorsCopy = {}
    Object.assign(registrationErrorsCopy, registrationErrors)
    if (registrationErrorsCopy.username) {
        if (registrationErrorsCopy.email) {
            registrationErrorsCopy.email.concat(registrationErrorsCopy.username)
        } else {
            registrationErrorsCopy.email = registrationErrorsCopy.username
        }
    }


    const onSubmit = (e) => {
        e.preventDefault()

        dispatch(register({
            first_name: first_name.current.value,
            last_name: last_name.current.value,
            email: email.current.value,
            username: email.current.value,
            password1: password1.current.value,
            password2: password2.current.value,
            ssn: ssn.current.value,
        }))
            .then((resp) => {
                if (resp.payload.success) {
                    setHasRegistered(true)
                }
            })
    }
    const backtologin = () => {
        dispatch(setActivePane(STEP_LOGIN))
    }

    return (
        <>
            <div className="container sign-up">
                <div className="row justify-content-center d-flex">
                    <div className="col-lg-6 mt-5">
                        <form onSubmit={onSubmit}>
                            <div className="button-row d-flex justify-content-between">
                                <button type="button" className="btn p-0" onClick={backtologin}><h3 className="modal-title sign-in" id="exampleModalLabel">Innskráning</h3></button>
                                <button type="button" className="btn active p-0 "><h3 className="sign-up">Nýskráning</h3></button>
                            </div>

                            {hasRegistered ?
                                <>
                                    <div className="alert alert-info" role="alert">
                                        <strong>Nýskráning tókst</strong>
                                        <p>Við höfum sent þér tölvupóst til þess að staðfesta netfangið þitt, vinsamlegast staðfestu netfangið þitt sem fyrst.</p>
                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn" onClick={backtologin}>
                                            Áfram
                                        </button>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="row">
                                        <div className="form-group col-md-6 label-left position-relative">
                                            <input placeholder=" " ref={first_name} type="text" className={`form-control ${registrationErrorsCopy.first_name ? 'is-invalid' : ''}`} id={`first_name-login`} />
                                            <label className="position-absolute" htmlFor={`first_name-login`}>Nafn</label>
                                            {registrationErrorsCopy.first_name &&
                                                <div className="invalid-feedback">
                                                    {registrationErrorsCopy.first_name}
                                                </div>
                                            }
                                        </div>
                                        <div className="form-group col-md-6 label-left position-relative">
                                            <input placeholder=" " ref={last_name} type="text" className={`form-control ${registrationErrorsCopy.first_name ? 'is-invalid' : ''}`} id={`last_name-login`} />
                                            <label className="position-absolute" htmlFor={`last_name-login`}>Eftirnafn</label>
                                            {registrationErrorsCopy.last_name &&
                                                <div className="invalid-feedback">
                                                    {registrationErrorsCopy.last_name}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group position-relative">
                                        <input placeholder=" " ref={email} type="text" className={`form-control ${registrationErrorsCopy.email ? 'is-invalid' : ''}`} aria-describedby="emailHelp" id={`email-login`} />
                                        <label className="position-absolute" htmlFor={`email-login`}>Netfang / Notandanafn</label>
                                        {registrationErrorsCopy.email &&
                                            <div className="invalid-feedback">
                                                {registrationErrorsCopy.email}
                                            </div>
                                        }
                                    </div>
                                    <div className="form-group position-relative">
                                        <input placeholder=" " ref={ssn} type="text" className={`form-control ${registrationErrorsCopy.ssn ? 'is-invalid' : ''}`} aria-describedby="ssnHelp" id={`ssn-login`} />
                                        <label className="position-absolute" htmlFor={`ssn-login`}>Kennitala greiðanda</label>
                                        {registrationErrorsCopy.ssn &&
                                            <div className="invalid-feedback">
                                                {registrationErrorsCopy.ssn}
                                            </div>
                                        }
                                    </div>
                                    <div className="form-group position-relative">
                                        <input placeholder=" " ref={password1} type="password" className={`form-control ${registrationErrorsCopy.password1 ? 'is-invalid' : ''}`} id={`password1-login`} />
                                        <label className="position-absolute" htmlFor={`password1-login`}>Lykilorð</label>
                                        {registrationErrorsCopy.password1 &&
                                            <div className="invalid-feedback">
                                                {registrationErrorsCopy.password1}
                                            </div>
                                        }
                                    </div>
                                    <div className="form-group position-relative">
                                        <input placeholder=" " ref={password2} type="password" className={`form-control ${registrationErrorsCopy.password2 ? 'is-invalid' : ''}`} id={`password2-login`} />
                                        <label className="position-absolute" htmlFor={`password-login`}>Staðfesta lykilorð</label>
                                        {registrationErrorsCopy.password2 &&
                                            <div className="invalid-feedback">
                                                {registrationErrorsCopy.password2}
                                            </div>
                                        }
                                    </div>
                                    <div className="modal-footer d-flex justify-content-center">
                                        <button type="submit" className="btn barlow semibold" onClick={onSubmit}>
                                            {props.isAuthenticating && <i className="fa fa-circle-o-notch fa-spin fa-fw"></i>}
                                            Áfram
                                        </button>
                                    </div>
                                </>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
