import React from 'react'
import { Provider } from 'react-redux'
import LoginWrapper from '../../components/login-wrapper'
import { store } from '../../store'

export default function LoginContainer(props) {
  
    return (
        <Provider store={store}>
            <LoginWrapper {...props} />
        </Provider>
    )
  }