import $ from 'jquery';
import "../../sass/main.scss";
import Lightbox from 'bs5-lightbox';


const options = {
	keyboard: true,
	size: 'fullscreen'
};

document.querySelectorAll('.lightbox-toggle').forEach((el) => el.addEventListener('click', (e) => {
	e.preventDefault();
	const lightbox = new Lightbox(el, options);
	lightbox.show();
}));
window.$ = $;
window.jQuery = $;
const bootstrap = require('bootstrap');
const select2 = require('select2');

require('./views/login')
require('./views/realEstateBlock')
require('./views/realEstateSidebar')
require('./views/subscription-tease-counter')
require ('./containers/search-container/index.js');