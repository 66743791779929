import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PlansService from "../../api/plans";
//import { setMessage } from "./message";


export const getPlans = createAsyncThunk(
  "plans/get",
  async (args, thunkAPI) => {
    try {
      const response = await PlansService.getPlans(args);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


const initialState = {planList: []};

const plansSlice = createSlice({
  name: "plans",
  initialState,
  extraReducers: {
    [getPlans.fulfilled]: (state, action) => {
      state.planList = action.payload;
    },
    [getPlans.rejected]: (state, action) => {
      state.error = "Ekki tókst að sækja áskriftarleiðir";
    },
  },
});

const { reducer } = plansSlice;
export default reducer;