import React from 'react'
import { useDispatch } from 'react-redux'
import { CloseIcon } from '../../components/icons'
import { setActivePane } from '../../slices/modal'


export default function ModalClose() {
    //const activePane = useSelector((state) => state.modal.activePane)
    const dispatch = useDispatch()

    const close = () => {
        const url = new URL(window.location);
        url.hash = '';
        history.replaceState(null, document.title, url);

        dispatch(setActivePane('login-button'))
    }

    return (
        <>
            <a className="login-modal-close" onClick={() => close()}>
                <CloseIcon />
            </a>
        </>
    )
}
