import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import searchService from "../../api/search";

export const getSearchResults = createAsyncThunk(
    "search/get",
    async (args, thunkAPI) => {
      try {
        const response = await searchService.getSearchResults(args);
        return response;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue();
      }
    }
  );


const initialState = {
    hasSearchResults: false,
    searchTerm: "",
    results: [],
};

const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setSearchTerm(state, term){
            state.searchTerm = term.payload
        },
        clearSearch(state){
            state.hasSearchResults = false
            state.searchTerm = ""
            state.results = []
        }
    },
    extraReducers: {
        [getSearchResults.fulfilled]: (state, results) => {
            state.hasSearchResults = results.payload && results.payload.length > 0;
            let sorted_results = results.payload.hits.sort(function(a,b) {
                return b.wagtailcore__Page.first_published_at - a.wagtailcore__Page.first_published_at
            });
            results.payload.hits = sorted_results;
            state.results = results.payload;
        },
        [getSearchResults.rejected]: (state, action) => {
            console.log("Action: ", action)
            state.error = "Ekki tókst að sækja leitarniðurstöður";
        },
    }
})

const { reducer } = searchSlice;
export default reducer;


