import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { STEP_LOGIN_BUTTON, STEP_PLAN_LIST, STEP_REGISTER } from '../../constants'
//import { useLoginMutation } from '../../slices/users'
import { login } from '../../slices/auth'
import { setActivePane } from '../../slices/modal'
import { GetQueryStringParams } from '../../utils'


export default function Login(props) {
    const redirectAfterLogin = useSelector((state) => state.modal.redirectAfterLogin)
    const dispatch = useDispatch()

    const email = useRef()
    const password = useRef()

    const { error } = useSelector((state) => state.auth);

    const onLogin = (e) => {
        e.preventDefault()

        dispatch(login({
            username: email.current.value,
            password: password.current.value,
        }))
        .then((resp) => {
            if  (resp.payload.user.authenticated) {
                if (redirectAfterLogin) {
                    window.location.href = redirectAfterLogin
                } else {
                    let redirect_url = GetQueryStringParams('redirect_url')
                    if (redirect_url) {
                        window.location.href = decodeURIComponent(redirect_url);
                    } else {
                        if (props.startingStep === STEP_PLAN_LIST) {
                            dispatch(setActivePane(STEP_PLAN_LIST))
                        } else {
                            dispatch(setActivePane(STEP_LOGIN_BUTTON))
                        }
                    }
                }
            }
        })

    }
    const RegisterOpen = () => {
        dispatch(setActivePane(STEP_REGISTER))
    }

    return (
        <>
            <div className="container">
                <div className="row justify-content-center d-flex">

                    <div className="col-lg-6 mt-5">
                        <div className="attention">
                        Í tengslum við útgáfu á nýjum vef Viðskiptablaðsins og Fiskifrétta er nauðsynlegt fyrir alla áskrifendur að „skrá“ sig inn á vefinn. Eldri notendanöfn virka áfram, en mikilvægt er að kennitala greiðanda áskriftar sé skráð eftir innskráningu til þess að virkja áskriftina þína.
                        </div>
                        <form onSubmit={onLogin}>
                            <div className="button-row d-flex justify-content-between">
                                <button type="button" className="btn active p-0"><h3 className="modal-title sign-in" id="exampleModalLabel">Innskráning</h3></button>
                                <button type="button" className="btn p-0" onClick={RegisterOpen}><h3 className="sign-up mb-0">Nýskráning</h3></button>
                            </div>

                            {error &&
                                <div className="alert alert-danger" role="alert">
                                    <strong>Villa!</strong> {error}
                                </div>
                            }

                            <div className="form-group position-relative">
                                <input placeholder=" " ref={email} type="text" className="form-control" aria-describedby="emailHelp" id={`email-login`} />
                                <label className="position-absolute" htmlFor={`email-login`}>Netfang</label>
                            </div>
                            <div className="form-group position-relative">
                                <input placeholder=" " ref={password} type="password" className="form-control" id={`password-login`} />
                                <label className="position-absolute" htmlFor={`password-login`}>Lykilorð</label>
                            </div>


                            <div className="modal-footer d-flex flex-column justify-content-center">
                                <button type="submit" className="btn barlow semibold mb-5" onClick={onLogin}>
                                    {props.isAuthenticating && <i className="fa fa-circle-o-notch fa-spin fa-fw"></i>}
                                    Innskrá
                                </button>
                                <a className="btn forgot-pass barlow" href="/accounts/password_reset/">Gleymt lykilorð?</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
