import axios from "axios";

const API_URL = "https://api-cdn.fasteignaleitin.is/graphql";

let propertyFields = `
    title
    id
    url
    price
    rooms
    bedrooms
    bathrooms
    propertyTypeLabel
    squareMeters
    coverPhoto {
        imageServiceUrl(width: 1000, mode: RESIZE)
    }
    openHouses {
        date
        fromTime
        toTime
    }
    address {
        postcode {
          areaNf
          placeNf
          code
        }
    }`


const getQuery = (args) => {
    let { type, take } = args
    let query = {
        'newest': `{properties(search: {order: {column: LATEST_EXTERNAL_PUBLISH, dir: DESC}} pagination: {take: ${take}}) { data { ${propertyFields} }}}`,
        'industrial': `{properties(search: {propertyTypes: [COMMERCIAL_PROPERTY], order: {column: LATEST_EXTERNAL_PUBLISH, dir: DESC}} pagination: {take: ${take}}) { data { ${propertyFields} }}}`,
        'openhouse': `{upcomingOpenHouses(pagination: {take: ${take}}) { ${propertyFields} }}`
    }
    return query[type]
}

const getRealEstate = (args) => {
    return axios
        .post(API_URL, {
            headers: {
                "content-type": "application/json",
            },
            query: getQuery(args)
        })
        .then((response) => {
            if(args.type == 'openhouse'){
                return response.data.data.upcomingOpenHouses
            }
            return response.data.data.properties.data;
        });
};



const realEstateService = {
    getRealEstate,
};

export default realEstateService;