import { applyMiddleware, combineReducers, createStore } from 'redux';
// import { stepRenderer } from './api/step-renderer/reducers'
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import authReducer from './slices/auth';
import modal from './slices/modal';
import plansReducer from './slices/plans';
import realEstateReducer from './slices/realEstate';
import subscriptionsReducer from './slices/subscriptions';
import temporaryTokenReducer from './slices/temporaryToken';
import searchReducer from './slices/search'


const loggerMiddleware = createLogger()

const reducers = combineReducers({
    modal,
    plans: plansReducer,
    subscriptions: subscriptionsReducer,
    auth: authReducer,
    tempToken: temporaryTokenReducer,
    realEstate: realEstateReducer,
    search: searchReducer,
})


let middleware = null
if(process.env.NODE_ENV === 'production') {
    middleware = applyMiddleware(
        thunkMiddleware,
        
    )
} else {
    middleware = composeWithDevTools(applyMiddleware(
        thunkMiddleware,
        loggerMiddleware,
    ))
}

export const store = createStore(reducers, middleware)
