import React, { useState, useEffect } from 'react';
import { getWithExpiry, setWithExpiry } from '../../utils';



export const SubscriptionTeaseCounter = ({ props }) => {

  const currentCount = getWithExpiry("pageCount") || 0;
  const newCount = currentCount + 1;
  const [showTeaser, setShowTeaser] = useState(false);

  useEffect(() => {

    getWithExpiry("pageCount")

    setWithExpiry("pageCount", newCount, 1000 * 60 * 60 * 24);

    if (newCount >= 11) {
      setShowTeaser(true);
    }
  }, []);

  useEffect(() => {
    const storySliced = document.getElementById('story-sliced');
    const storyFull = document.getElementById('story-full');

    if (storySliced && storyFull) {
      if (showTeaser) {
        storySliced.classList.remove('d-none');
        storyFull.classList.add('d-none');
      } else {
        storySliced.classList.add('d-none');
        storyFull.classList.remove('d-none');
      }
    }
  }, [showTeaser]);

  return (
    <>
      {showTeaser && (
        <div class="subscription-tease not-authenticated">
          <div class="info">
            <h1 class="mb-3">Skráðu þig inn...</h1>
            <h3>...og lestu allar opnar fréttir frítt</h3>
          </div>
          <a href="#innskraning" class="subscribe d-inline-block text-decoration-none position-absolute">Innskráning</a>
          <span data-login-button data-button-text="Ég er þegar áskrifandi" data-hide-user-status="true" class="fw-bold login barlow position-absolute d-flex align-items-center text-center w-100 modal-btn justify-content-center text-center"></span>
        </div>
      )}
    </>
  )
}