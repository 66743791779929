import axios from "axios";
import { getCookie } from "../../utils";

const API_URL = "/api/v1/";


const getPlans = (args) => {
    let params = ''
    if (args && args['offer'] && args['offer'] === true) {
        params = '?offer=true'
    }
    
    return axios
        .get(API_URL + `plans/${params}`, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};



const plansService = {
    getPlans,
};

export default plansService;