import axios from "axios";
import { getCookie } from "../../utils";

const API_URL = "/api/v1/";


const getSearchResults = (args) => {
    return axios
        .get(API_URL + `search/?search=${args.searchString}&p=${args.page ? args.page : '0'}`, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data;
        });
};



const searchService = {
    getSearchResults,
};

export default searchService;