import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { STEP_LOGIN, STEP_PAYMENT_INFORMATION } from '../../constants'
import { setActivePane } from '../../slices/modal'


export default function CheckLogin() {

    const dispatch = useDispatch()

    const { user: currentUser } = useSelector((state) => state.auth);

    if (currentUser && currentUser.authenticated) {
        dispatch(setActivePane(STEP_PAYMENT_INFORMATION))
    } else {
        dispatch(setActivePane(STEP_LOGIN))
    }

    return (
        <></>
    )
}
