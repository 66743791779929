import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AskellService from "../../api/askell";

export const createTemporaryToken = createAsyncThunk(
  "temporaryToken/create",
  async ({ cardObj, planId }, thunkAPI) => {
    try {
      const response = await AskellService.createTemporaryToken(cardObj, planId);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
); 
export const getTemporaryTokenStatus = createAsyncThunk(
  "temporaryToken/get",
  async ({ token }, thunkAPI) => {
    try {
      const response = await AskellService.getTemporaryTokenStatus(token);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
); 


const initialState = { tempToken: null, cardVerificationUrl: null, error: null, tokenStatus: null };

const temporaryTokenSlice = createSlice({
  name: "temporaryToken",
  initialState,
  extraReducers: {
    [createTemporaryToken.fulfilled]: (state, action) => {
      state.tempToken = action.payload.token;
      state.cardVerificationUrl = action.payload.card_verification_url;
      state.tokenStatus = 'pending'
      state.error = null
    },
    [createTemporaryToken.rejected]: (state, action) => {
      state.tempToken = null;
      state.cardVerificationUrl = null;
      state.error = "Ekki tókst að vista kort";
      state.tokenStatus = null
    },
    [getTemporaryTokenStatus.fulfilled]: (state, action) => {
      state.tokenStatus = action.payload.status
      if (action.payload.status === 'failed') {
        state.cardVerificationUrl = null
        state.tempToken = null
        state.error = "Ekki tókst að staðfesta kort."
      }
      state.error = null
    },
    [getTemporaryTokenStatus.rejected]: (state, action) => {
      state.tokenStatus = action.payload.status
      state.error = "Ekki tókst að staðfesta kort."
    },
  },
});

const { reducer } = temporaryTokenSlice;
export default reducer;