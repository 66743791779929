import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { STEP_LOGIN, STEP_LOGIN_BUTTON, STEP_MANAGE } from '../../constants'
import { logout } from '../../slices/auth'
import { setActivePane } from '../../slices/modal'

export default function LoginButton(props) {
    const dispatch = useDispatch()
    const activePane = useSelector((state) => state.modal.activePane)
    const { user: currentUser } = useSelector((state) => state.auth);

    const { startingStep, hidden, hideUserStatus, redirectUrl } = props

    let name = ''
    if (currentUser && currentUser.authenticated) {
        name = `${currentUser.first_name} ${currentUser.last_name}`
        if (name === ' ') {
            name = currentUser.username
        }
    }
    if (hidden) {
        return <></>
    }



    return (
        <>
            {currentUser && currentUser.authenticated && startingStep === STEP_LOGIN ?
                <>
                    {!hideUserStatus &&
                        <div className="d-none d-md-block">
                            <span onClick={() => dispatch(setActivePane(STEP_MANAGE))}>{name} </span><span className="mx-1">|</span> <a href={void (0)} onClick={() => dispatch(logout())}>Útskráning</a>
                        </div>
                    }
                    <div className={`d-block d-md-none ${currentUser.authenticated && 'logged-in position-relative'} `} onClick={() => dispatch(setActivePane(STEP_MANAGE))} >
                        <svg className="user-icon" width="25px" height="23px" viewBox="0 0 25 23" version="1.1" aria-label="Innskráning">
                            <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Artboard" transform="translate(-20.000000, -31.000000)" fill="#000000" fillRule="nonzero">
                                    <g id="noun-user-4602168" transform="translate(20.000000, 31.000000)">
                                        <path d="M12.5,1.43045479 C9.00341567,1.43045479 6.16866672,4.31294688 6.16866672,7.86842105 C6.16866672,11.4241405 9.00341567,14.3063873 12.5,14.3063873 C15.9968255,14.3063873 18.8313333,11.4241405 18.8313333,7.86842105 C18.8313333,4.31294688 15.9968255,1.43045479 12.5,1.43045479 Z M4.76190476,7.86842105 C4.76190476,3.52279383 8.22647063,0 12.5,0 C16.7738912,0 20.2380952,3.52291645 20.2380952,7.86842105 C20.2380952,12.2142935 16.7737706,15.7368421 12.5,15.7368421 C8.22635004,15.7368421 4.76190476,12.2141709 4.76190476,7.86842105 Z" id="Shape"></path>
                                        <path d="M12.4997119,19.4712838 C7.93539391,19.4712838 3.81430646,20.8247539 0.798356613,23 L0,21.9429726 C3.26510856,19.5883184 7.6712534,18.1578947 12.5,18.1578947 C17.3283432,18.1578947 21.7345457,19.5883184 25,21.9429726 L24.2016434,23 C21.1856935,20.8247539 17.0645485,19.4712838 12.5002881,19.4712838 L12.4997119,19.4712838 Z" id="Path"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                </>
                :
                <a href={void (0)} onClick={() => dispatch(setActivePane({activePane: props.startingStep, redirectAfterLogin: redirectUrl}))}>
                    <span className="button-text">{props.buttonText}</span>
                    <svg className="d-md-none d-block user-icon" width="25px" height="23px" viewBox="0 0 25 23" version="1.1" aria-label="Innskráning">
                        <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="Artboard" transform="translate(-20.000000, -31.000000)" fill="#000000" fillRule="nonzero">
                                <g id="noun-user-4602168" transform="translate(20.000000, 31.000000)">
                                    <path d="M12.5,1.43045479 C9.00341567,1.43045479 6.16866672,4.31294688 6.16866672,7.86842105 C6.16866672,11.4241405 9.00341567,14.3063873 12.5,14.3063873 C15.9968255,14.3063873 18.8313333,11.4241405 18.8313333,7.86842105 C18.8313333,4.31294688 15.9968255,1.43045479 12.5,1.43045479 Z M4.76190476,7.86842105 C4.76190476,3.52279383 8.22647063,0 12.5,0 C16.7738912,0 20.2380952,3.52291645 20.2380952,7.86842105 C20.2380952,12.2142935 16.7737706,15.7368421 12.5,15.7368421 C8.22635004,15.7368421 4.76190476,12.2141709 4.76190476,7.86842105 Z" id="Shape"></path>
                                    <path d="M12.4997119,19.4712838 C7.93539391,19.4712838 3.81430646,20.8247539 0.798356613,23 L0,21.9429726 C3.26510856,19.5883184 7.6712534,18.1578947 12.5,18.1578947 C17.3283432,18.1578947 21.7345457,19.5883184 25,21.9429726 L24.2016434,23 C21.1856935,20.8247539 17.0645485,19.4712838 12.5002881,19.4712838 L12.4997119,19.4712838 Z" id="Path"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                </a>
            }
        </>
    )
}

LoginButton.defaultProps = {
    user: {},
    startingStep: STEP_LOGIN,
    buttonText: 'Innskráning',
    hidden: false,
    hideUserStatus: false
}