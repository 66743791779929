import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../api/auth";
//import { setMessage } from "./message";


const user = JSON.parse(localStorage.getItem("user"));

export const register = createAsyncThunk(
  "auth/register",
  async ({ first_name, last_name, email, username, password1, password2, ssn }, thunkAPI) => {
    try {
      const response = await AuthService.register(first_name, last_name, email, username, password1, password2, ssn);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(username, password);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});

export const checkLogin = createAsyncThunk(
  "auth/checkLogin",
  async (args, thunkAPI) => {
    try {
      const data = await AuthService.checkLogin();
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const updateUser = createAsyncThunk(
  "auth/updateUser",
  async ({field, newValue}, thunkAPI) => {
    try {
      const data = await AuthService.updateUser(field, newValue);
      return { user: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


const initialState = user
  ? { isLoggedIn: true, user, error: null, registrationErrors: {} }
  : { isLoggedIn: false, user: null, error: null, registrationErrors: {} };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
    },
    [register.rejected]: (state, action) => {

      state.isLoggedIn = false;
      state.registrationErrors = action.payload.errors
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.error = "Ekki tókst að auðkenna notanda.";
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [checkLogin.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [checkLogin.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.error = "Ekki tókst að auðkenna notanda.";
      state.user = null;
    },
    [updateUser.fulfilled]: (state, action) => {
      if (action.payload.user.error ) {
        state.error = action.payload.user.error;
      } else if (action.payload.user) {
        state.user = action.payload.user;
      }
    },
    [updateUser.rejected]: (state, action) => {
    },
  },
});

const { reducer } = authSlice;
export default reducer;