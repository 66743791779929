import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    activePane: null,
    selectedPlan: null,
    redirectAfterLogin: null
  },
  reducers: {
    setActivePane: (state, action) => {
      if (Object.keys(action.payload).includes('redirectAfterLogin')) {

        state.activePane = action.payload.activePane
        state.redirectAfterLogin = action.payload.redirectAfterLogin
      } else {
        state.activePane = action.payload
      }
    },
    setActivePlan: (state, action) => {
      state.selectedPlan = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setActivePane, setActivePlan } = modalSlice.actions

export default modalSlice.reducer