import axios from "axios";
import { getCookie } from "../../utils";

const API_URL = "/api/v1/";

const register = (first_name, last_name, email, username, password1, password2, ssn) => {
    return axios.post(API_URL + "user/register/", {
        first_name, last_name, email, username, password1, password2, ssn
    }, {
        headers: {
            'X-CSRFToken': getCookie("csrftoken"),
        }
    });
};

const login = (username, password) => {
    return axios
        .post(API_URL + "login/", {
            username,
            password,
        }, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            if (response.data.authenticated) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            
            return response.data;
        });
};


const checkLogin = () => {
    return axios
        .get(API_URL + "user/current/", {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            if (response.data.authenticated) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response.data;
        });
};


const logout = () => {
    return axios
        .get(API_URL + "logout/", {
        })
        .then((response) => {
            localStorage.removeItem("user");
        });
};


const updateUser = (field, newValue) => {
    return axios
        .put(API_URL + "user/update_user/", {
            field: field,
            new_string: newValue
        }, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            if (response.data.authenticated) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
};

const authService = {
    register,
    login,
    logout,
    checkLogin,
    updateUser,
};

export default authService;