import React from 'react'
import { Provider } from 'react-redux'
import RealEstateSidebar from './real-estate-sb'
import { store } from '../../store'

export default function RealEstateSidebarContainer(props) {
  
    return (
        <Provider store={store}>
            <RealEstateSidebar {...props} />
        </Provider>
    )
  }