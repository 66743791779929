import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchResults } from '../../slices/search';

export const SearchContainer = () => {
    const dispatch = useDispatch()
    const [searchInput, setSearchInput] = useState(new URLSearchParams(window.location.search).get('q') || '');
    const [searchPage, setSearchPage] = useState(new URLSearchParams(window.location.search).get('page') || 0);
    const searchResults = useSelector((state) => state.search.results)
    const searchBox = useRef()


    useEffect(() => {
        if (searchInput.length >= 2) {
            dispatch(getSearchResults({searchString: searchInput, page: searchPage}))
        }
    }, [searchInput, searchPage])

    useEffect(() => {
        searchBox.current.focus()
    }, [])


    return (
        <>
            <div className='row justify-content-center'>
                <div className="col-xl-4 col-lg-6 col-md-8 col-sm-10 col-15 position-relative text-center position-relative">
                    <input ref={searchBox} className="border-0 w-100" type="text" placeholder="Sláðu inn leitarorð" value={searchInput} onChange={e => { setSearchInput(e.target.value); setSearchPage(0); }}></input>
                    <div className='position-absolute start-0 search-bg w-100'>
                        <div className='position-absolute submit-search close' onClick={() => { searchBox.current.value = ''; setSearchInput('') }}></div>
                    </div>
                </div>
            </div>
            {searchInput.length >= 2 &&
                <>
                    <div className='row justify-content-center'>
                        <div className="col-12 position-relative mb-5 mt-4">
                            {searchResults.length == 0 && <h2 className='d-inline-block me-3'>Engar niðurstöður fundust sem innihalda </h2>}
                        </div>
                    </div>
                    {searchResults.hits && searchResults.hits.length > 0 &&
                        <div className='row justify-content-center'>
                            <div className="w-100"></div>
                            {searchResults && searchResults.hits && searchResults.hits.map((article, index) => {
                                return( <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-12">
                                            <div className="article-wrapper article-h1 article-w3" dangerouslySetInnerHTML={{__html: article.formatted_search}} ></div>
                                        </div>);
                            })}
                        </div>
                    }
                    {searchResults && searchResults.pages && searchResults.pages > 1 &&
                            <div className="clearfix text-center">
                                <nav className="d-inline-block">
                                    <ul className="pagination pagination-lg">
                                        {[...Array(searchResults.pages).keys()].map((page, index) => {
                                            return (<li className={`page-item flex-fill ${page == searchResults.page && 'active'}`}>
                                                <a  className="page-link" href="#" onClick={() => setSearchPage(page+1)}>{page+1}</a>
                                            </li>)
                                        })}
                                    </ul>
                                </nav>
                          </div>
                    }
                </>
            }
        </>
    );
};
