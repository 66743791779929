import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SubscriptionsService from "../../api/subscriptions";
//import { setMessage } from "./message";


export const getSubscriptions = createAsyncThunk(
  "subscriptions/get",
  async (thunkAPI) => {
    try {
      const response = await SubscriptionsService.getSubscriptionDetails();
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCustomer = createAsyncThunk(
  "customers/get",
  async (thunkAPI) => {
    try {
      const response = await SubscriptionsService.getCustomerDetails();
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const subscribe = createAsyncThunk(
  "subscriptions/create",
  async (args, thunkAPI) => {
    try {
      const response = await SubscriptionsService.subscribe(args);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "cancel/post",
  async (args, thunkAPI) => {
    try {
      const response = await SubscriptionsService.cancelSubscription(args);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateCard = createAsyncThunk(
  "customer/updateCard",
  async (args, thunkAPI) => {
    try {
      const response = await SubscriptionsService.updateCard(args);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateAddress = createAsyncThunk(
  "customer/updateAddress",
  async (args, thunkAPI) => {
    try {
      const response = await SubscriptionsService.updateAddress(args);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


const initialState = { subscriptionList: [], customer: {}, error: null, updateAddressLoading: false };

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  extraReducers: {
    [getSubscriptions.fulfilled]: (state, action) => {
      state.subscriptionList = action.payload;
      state.error = null
    },
    [getSubscriptions.rejected]: (state, action) => {
      state.error = "Ekki tókst að sækja áskriftir";
    },
    [getCustomer.fulfilled]: (state, action) => {
      state.customer = action.payload;
      state.error = null
    },
    [getCustomer.rejected]: (state, action) => {
      state.error = "Ekki tókst að sækja viðskiptavin";
    },
    [subscribe.fulfilled]: (state, action) => {
      state.tempToken = action.payload.token;
    },
    [subscribe.rejected]: (state, action) => {
      state.tempToken = null;
    },
    [updateCard.fulfilled]: (state, action) => {
      
    },
    [updateCard.rejected]: (state, action) => {
      
    },
    [updateAddress.pending]: (state, action) => {
      state.updateAddressLoading = true
    },
    [updateAddress.fulfilled]: (state, action) => {
      state.updateAddressLoading = false
      state.customer = action.payload.response;
    },

  },
});




const { reducer } = subscriptionsSlice;
export default reducer;