import React from 'react'
import { Provider } from 'react-redux'
import LoginButton from '../../components/login-button'
import { STEP_LOGIN } from '../../constants'
import { store } from '../../store'

export default function LoginButtonContainer(props) {
  
    return (
        <Provider store={store}>
            <LoginButton {...props} />
        </Provider>
    )
  }