import React, { useEffect, useState, useRef } from 'react';
import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { useDispatch, useSelector } from 'react-redux';
import { STEP_PLAN_LIST } from '../../constants';
import { subscribe } from '../../slices/subscriptions';
import { setActivePane } from '../../slices/modal';
import { createTemporaryToken, getTemporaryTokenStatus } from '../../slices/temporaryToken';
const kennitala = require('kennitala')

let timer = null

const ERROR_MESSAGES = {
    emptyCardNumber: 'Sláðu inn kortanúmer',
    invalidCardNumber: 'Þetta númer er ógilt',
    emptyExpiryDate: 'Sláðu inn gildistíma',
    monthOutOfRange: 'Mánuður þarf að vera milli 01 og 12',
    yearOutOfRange: 'Árið má ekki vera liðið',
    dateOutOfRange: 'Gildistíminn má ekki vera liðinn',
    invalidExpiryDate: 'Ógildur gildistími',
    emptyCVC: 'Sláðu inn öryggisnúmer (CVC)',
    invalidCVC: 'Öryggisnúmer er ógilt'
}

export default function PaymentInformation() {
    const customerReferenceRef = React.createRef()
    const cardHolderNameRef = React.createRef()
    const distributionNameRef = React.createRef()
    const distributionAddressRef = React.createRef()
    const distributionZipRef = React.createRef()
    const payerNameRef = React.createRef()
    const payerReferenceRef = React.createRef()
    const payerAddressRef = React.createRef()
    const payerZipRef = React.createRef()
    const inputsRef = useRef()
    const differentPayerRef = useRef()

    const dispatch = useDispatch()
    const selectedPlan = useSelector((state) => state.modal.selectedPlan)
    const tempToken = useSelector((state) => state.tempToken.tempToken)
    const tempTokenError = useSelector((state) => state.tempToken.error)
    const cardVerificationUrl = useSelector((state) => state.tempToken.cardVerificationUrl)
    const tokenStatus = useSelector((state) => state.tempToken.tokenStatus)
    const user = useSelector((state) => state.auth.user)

    const [display, setDisplay] = useState('form')
    const [submitDisabled, setSubmitDisabled] = useState(false)

    // card number setters and getters
    const [cardNumber, setCardNumber] = useState('')
    const [expiryDate, setExpiryDate] = useState('')
    const [cvc, setCVC] = useState('')

    const [differentPayer, setDifferentPayer] = useState(false)

    const [inputs, setInputs] = useState({});

    useEffect(() => {
        setInputs({customerReference: customerReferenceRef.current.value})
        // setDisplay('done')
    }, [])
    
    useEffect(() => {
        inputsRef.current = {
            ...inputs,
            differentPayer,
            selectedPlan,
        }
      }, [inputs, differentPayer, selectedPlan]);
    const handleInputChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        console.log(tokenStatus)
        if (tokenStatus === 'tokencreated') {
            clearInterval(timer)
            timer = null
            setDisplay('almost-done')
            let data = {
                ...inputsRef.current,
                tempToken,
            }
            Promise.resolve(dispatch(subscribe(data))).then((response) => {
                setDisplay('done')
            })
        }
        if (tokenStatus === 'failed') {
            clearInterval(timer)
            timer = null
            setDisplay('failed')
        }
        // if (tokenStatus === 'initial') {
        //     clearInterval(timer)
        //     timer = null
        //     setDisplay('form')
        // }
    }, [tokenStatus]);

    const handleChangeCardNumber = (e) => {
        setCardNumber(e.target.value)
    }
    const handleChangeExpiryDate = (e) => {
        setExpiryDate(e.target.value)
    }
    const handleChangeCVC = (e) => {
        setCVC(e.target.value)
    }

    const cardNumberValidator = ({ cardNumber, cardType, errorMessages }) => {
        if (cardType.displayName === 'Visa' || cardType.displayName === 'Mastercard') {
            return;
        }
        return 'Einungis Visa eða Mastercard kort eru samþykkt.';
    }


    const {
        meta,
        getCardImageProps,
        getCardNumberProps,
        getExpiryDateProps,
        getCVCProps,
    } = usePaymentInputs({
        cardNumberValidator,
        errorMessages: ERROR_MESSAGES,
    });

    let cardNumberProps = getCardNumberProps({ onChange: handleChangeCardNumber })
    cardNumberProps['aria-label'] = 'Kortanúmer'
    cardNumberProps.placeholder = 'Kortanúmer'
    let expiryDateProps = getExpiryDateProps({ onChange: handleChangeExpiryDate })
    expiryDateProps['aria-label'] = 'Gildistími á forminu MM ÁÁ'
    expiryDateProps.placeholder = 'MM/ÁÁ'
    let CVCProps = getCVCProps({ onChange: handleChangeCVC })
    CVCProps.placeholder = 'CVC'


    const buttonDisabled = () => {
        return Object.keys(validationErrors).length > 0 && meta.erroredInputs.cardNumber === undefined && meta.erroredInputs.cvc === undefined && meta.erroredInputs.expiryDate === undefined && this.state.cardObj.cardholderName !== ''
    }

    const validateForm = () => {
        let valid = true

        let errors = {}

        if (!customerReferenceRef.current.value) {
            errors.customerReference = 'Vantar kennitölu'
            valid = false
        }
        if (!kennitala.isValid(customerReferenceRef.current.value)) {
            errors.customerReference = 'Kennitala er ógild'
            valid = false
        }
        if (!cardHolderNameRef.current.value) {
            errors.cardHolderName = 'Vantar nafn korthafa'
            valid = false
        }
        if (!selectedPlan.electronic_only) {
            if (!distributionNameRef.current.value) {
                errors.distributionName = 'Vantar nafn viðtakanda'
                valid = false
            }
            if (!distributionAddressRef.current.value) {
                errors.distributionAddress = 'Vantar heimilisfang viðtakanda'
                valid = false
            }
            if (!distributionZipRef.current.value) {
                errors.distributionZip = 'Vantar póstfang viðtakanda'
                valid = false
            }
        }
        if (differentPayer) {
            if (!payerReferenceRef.current.value) {
                errors.payerReference = 'Vantar kennitölu greiðanda'
                valid = false
            }
            if (!payerNameRef.current.value) {
                errors.payerName = 'Vantar nafn greiðanda'
                valid = false
            }
            if (!payerAddressRef.current.value) {
                errors.payerAddress = 'Vantar heimilisfang greiðanda'
                valid = false
            }
            if (!payerZipRef.current.value) {
                errors.payerZip = 'Vantar póstfang greiðanda'
                valid = false
            }
        }
        setValidationErrors(errors)
        if (!valid) {
            setSubmitDisabled(false)
        }
        return valid
    }

    const onSubmit = (e) => {
        setSubmitDisabled(true)
        const isValid = validateForm()

        if (isValid) {
            Promise.resolve(dispatch(createTemporaryToken({
                cardObj: {
                    cardNumber,
                    expiryDate,
                    cvc,
                },
                planId: selectedPlan.plan_id
            }))).then((response) => {
               
                if ( timer === null && response.payload.token) {
                    timer = setInterval(() => dispatch(getTemporaryTokenStatus({token: response.payload.token})), 1000)
                }
                if (response.meta.requestStatus !== 'rejected') {
                    setDisplay('iframe')
                }
                else {
                    setDisplay('form')
                }

                setSubmitDisabled(false)
            })
        }
    }

    let defaultValues = {
        ssn: user.profile.ssn === '' ? null : user.profile.ssn,

    }
    if (user.first_name || user.last_name) {
        defaultValues.name = `${user.first_name? user.first_name+' ' : ''}${user.last_name}`
    }

    

    return (
        <>
            {(display === 'form' || display === 'failed') &&
                <div className="container payment-modal">
                    <div className="row justify-content-center">
                        <div className="col-md-6 border info-container">
                            <div className="plans-header d-flex">
                                <div className="col-11 text-start">
                                    <span className="barlow">Valin áskriftarleið</span>
                                    <h2>{selectedPlan.name}</h2>
                                </div>
                                <div className="col-1 d-flex align-items-center">
                                    <a className="barlow" onClick={() => dispatch(setActivePane(STEP_PLAN_LIST))}>Breyta</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            { display === 'failed' &&
                                <div className="alert alert-danger mt-5" role="alert">
                                    <strong>Það kom upp villa við að skrá kortið þitt. <br />Vinsamlegast reyndu síðar eða hafðu samband við okkur ef vandamálið leysist ekki.</strong>
                                </div>
                            }

                            { tempTokenError &&
                                <div className="alert alert-danger mt-5" role="alert">
                                    <strong>{tempTokenError}</strong>
                                </div>
                            }

                            <form>
                                <div className="row justify-content-center d-flex">
                                    <div className="plans-header text-center">
                                        <h3>Greiðsluupplýsingar</h3>
                                    </div>
                                </div>
                                <div className="row justify-content-center d-flex">
                                    <div className="form-group position-relative">
                                        <input value={inputs.customerReference} defaultValue={defaultValues.ssn} onChange={handleInputChange} autoComplete="kennitala" ref={customerReferenceRef} placeholder=" " type="text" className={`form-control ${validationErrors.customerReference ? 'is-invalid' : ''}`} aria-describedby="customerReferenceHelp" id={`customerReference`} name="customerReference" />
                                        <label className="position-absolute" htmlFor={`customerReference`}>Kennitala</label>
                                        {validationErrors.customerReference &&
                                            <div className="invalid-feedback">
                                                {validationErrors.customerReference}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="row justify-content-center d-flex">
                                    <div className="form-group position-relative">
                                        <input defaultValue={defaultValues.name} autoComplete="cc-name" placeholder=" " ref={cardHolderNameRef} type="text" className={`form-control ${validationErrors.cardHolderName ? 'is-invalid' : ''}`} aria-describedby="cardHolderNameHelp" id={`cardHolderName`} />
                                        <label className="position-absolute" htmlFor={`cardHolderName`}>Korthafi</label>
                                        {validationErrors.cardHolderName &&
                                            <div className="invalid-feedback">
                                                {validationErrors.cardHolderName}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="row justify-content-center d-flex">
                                    <div className="form-group position-relative ">
                                        <svg className="position-absolute" {...getCardImageProps({ images })} />
                                        <input {...cardNumberProps} value={cardNumber} placeholder=" " className={`form-control ${meta.isTouched && meta.erroredInputs.cardNumber ? 'is-invalid' : ''}`} />
                                        <label className="position-absolute" htmlFor="cardNumber">Kortanúmer</label>
                                        {meta.isTouched && meta.erroredInputs.cardNumber &&
                                            <div className="invalid-feedback">
                                                {meta.erroredInputs.cardNumber}
                                            </div>
                                        }
                                    </div>
                                    <div className="form-group position-relative ">
                                        <input {...expiryDateProps} value={expiryDate} placeholder=" " className={`form-control ${meta.isTouched && meta.erroredInputs.expiryDate ? 'is-invalid' : ''}`} />
                                        <label className="position-absolute" htmlFor="expiryDate">Gildistími</label>
                                        {meta.isTouched && meta.erroredInputs.expiryDate &&
                                            <div className="invalid-feedback">
                                                {meta.erroredInputs.expiryDate}
                                            </div>
                                        }
                                    </div>
                                    <div className="form-group position-relative ">
                                        <input {...CVCProps} value={cvc} placeholder=" " className={`form-control ${meta.isTouched && meta.erroredInputs.cvc ? 'is-invalid' : ''}`} />
                                        <label className="position-absolute" htmlFor="cvc">CVC</label>
                                        {meta.isTouched && meta.erroredInputs.cvc &&
                                            <div className="invalid-feedback">
                                                {meta.erroredInputs.cvc}
                                            </div>
                                        }
                                    </div>
                                </div>
                                {!selectedPlan.electronic_only &&
                                    <>
                                       <div className="row justify-content-center d-flex mt-5">
                                            <div className="plans-header text-center">
                                                <h3>Upplýsingar um viðtakanda</h3>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center d-flex">
                                            <div className="form-group position-relative ">
                                                <input value={inputs.distributionName || ''} placeholder=" " onChange={handleInputChange} ref={distributionNameRef} type="text" className={`form-control ${validationErrors.distributionName ? 'is-invalid' : ''}`} id={`distributionName`} name={`distributionName`} />
                                                <label className="position-absolute" htmlFor="distributionName">Nafn viðtakanda</label>
                                                {validationErrors.distributionName &&
                                                    <div className="invalid-feedback">
                                                        {validationErrors.distributionName}
                                                    </div>
                                                }
                                            </div>
                                            <div className="form-group position-relative ">
                                                <input value={inputs.distributionAddress || ''} onChange={handleInputChange} placeholder=" " ref={distributionAddressRef} type="text" className={`form-control ${validationErrors.distributionAddress ? 'is-invalid' : ''}`} id={`distributionAddress`} name={`distributionAddress`} />
                                                <label className="position-absolute" htmlFor="distributionAddress">Heimilisfang viðtakanda</label>
                                                {validationErrors.distributionAddress &&
                                                    <div className="invalid-feedback">
                                                        {validationErrors.distributionAddress}
                                                    </div>
                                                }
                                            </div>
                                            <div className="form-group position-relative ">
                                                <input value={inputs.distributionZip || ''} onChange={handleInputChange} placeholder=" " ref={distributionZipRef} type="number" className={`form-control ${validationErrors.distributionZip ? 'is-invalid' : ''}`} id={`distributionZip`} name={`distributionZip`} />
                                                <label className="position-absolute" htmlFor="distributionZip">Póstnúmer</label>
                                                {validationErrors.distributionZip &&
                                                    <div className="invalid-feedback">
                                                        {validationErrors.distributionZip}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {!differentPayer ?
                                            <></>// <a onClick={() => setDifferentPayer(true)} >Velja annan greiðanda</a>
                                            :
                                            <a onClick={() => setDifferentPayer(false)} >Hætta við</a>
                                        }
                                    </>
                                }
                                {differentPayer &&
                                    <>
                                        <div className="row justify-content-center d-flex mt-5">
                                            <div className="plans-header text-center">
                                                <h3>Upplýsingar um greiðanda</h3>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center d-flex">
                                            <div className="form-group position-relative ">
                                                <input value={inputs.payerReference || ''} onChange={handleInputChange} placeholder=" " ref={payerReferenceRef} type="text" className={`form-control ${validationErrors.payerReference ? 'is-invalid' : ''}`} id={`payerReference`} name={`payerReference`} />
                                                <label className="position-absolute" htmlFor="payerReference">Kennitala greiðanda</label>
                                                {validationErrors.payerReference &&
                                                    <div className="invalid-feedback">
                                                        {validationErrors.payerReference}
                                                    </div>
                                                }
                                            </div>
                                            <div className="form-group position-relative ">
                                                <input value={inputs.payerName || ''} onChange={handleInputChange} placeholder=" " ref={payerNameRef} type="text" className={`form-control ${validationErrors.payerName ? 'is-invalid' : ''}`} id={`payerName`} name={`payerName`} />
                                                <label className="position-absolute" htmlFor="payerName">Nafn greiðanda</label>
                                                {validationErrors.payerName &&
                                                    <div className="invalid-feedback">
                                                        {validationErrors.payerName}
                                                    </div>
                                                }
                                            </div>
                                            <div className="form-group position-relative ">
                                                <input value={inputs.payerAddress || ''} onChange={handleInputChange} placeholder=" " ref={payerAddressRef} type="text" className={`form-control ${validationErrors.payerAddress ? 'is-invalid' : ''}`} id={`payerAddress`} name={`payerAddress`} />
                                                <label className="position-absolute" htmlFor="payerAddress">Heimilisfang viðtakanda</label>
                                                {validationErrors.payerAddress &&
                                                    <div className="invalid-feedback">
                                                        {validationErrors.payerAddress}
                                                    </div>
                                                }
                                            </div>
                                            <div className="form-group position-relative ">
                                                <input value={inputs.payerZip || ''} onChange={handleInputChange} placeholder=" " ref={payerZipRef} type="number" className={`form-control ${validationErrors.payerZip ? 'is-invalid' : ''}`} id={`payerZip`} name={`payerZip`} />
                                                <label className="position-absolute" htmlFor="payerZip">Póstnúmer</label>
                                                {validationErrors.payerZip &&
                                                    <div className="invalid-feedback">
                                                        {validationErrors.payerZip}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="modal-footer d-flex flex-column justify-content-center">
                                    <button disabled={submitDisabled} type="button" className="btn btn-primary" onClick={onSubmit}>
                                        {submitDisabled && <i className="fa fa-circle-o-notch fa-spin fa-fw me-3"></i>}
                                        Greiða
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
            {display === 'iframe' &&
                <div className="container">
                    <div className="row justify-content-center d-flex">
                        <div className="plans-header text-center col-16 col-md-6">
                            <iframe src={cardVerificationUrl} />
                        </div>
                    </div>
                </div>
            }
            {display === 'done' &&
                <div className="container payment-confirmed">
                    <div className="row justify-content-center d-flex">
                        <div className="plans-header text-center">
                            <h2 className="mb-4">Glæsilegt!</h2>
                            <p>Þú munt fá tölvupóst til staðfestingar á greiðslu.</p>
                            <p>Greiðslukortið verður skuldfært mánaðarlega þar til áskrift er sagt upp.</p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
