import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { STEP_CHECK_LOGIN } from '../../constants'
import { setActivePane, setActivePlan } from '../../slices/modal'
import { getPlans } from '../../slices/plans'
import { formatISK } from '../../utils'

export default function PlanList() {

    const dispatch = useDispatch()
    const selectedPlan = useSelector((state) => state.modal.selectedPlan)
    const plans = useSelector((state) => state.plans.planList)
    const error = useSelector((state) => state.plans.error)

    const [planFilter, setPlanFilter] = useState(null)

    useEffect(() => {
        let params = {}
        let planFilter = ''
        if (window.location.search.includes('?tilbod')) {
            let searchParts = window.location.search.split("-")
            if (searchParts.length > 0) {
                if (searchParts[1].includes('&')) {
                    searchParts = searchParts[1].split('&')
                    planFilter = searchParts[0]
                } else {
                    planFilter = searchParts[1]
                }
                setPlanFilter(planFilter)
            }
            params = {
                offer: true
            }
        }
        dispatch(getPlans(params))
    }, [])


    const selectPlanAndSetStep = (planId) => {
        Promise.resolve(dispatch(setActivePlan(planId)))
            .then(() => {
                dispatch(setActivePane(STEP_CHECK_LOGIN))
            })

    }

    let filteredPlans = plans
    if (planFilter) {
        filteredPlans = plans.filter(p => p.offer_code === planFilter)
    }


    return (
        <>
            <div className="container">
                <div className="row justify-content-center d-flex">
                    <div className="plans-header col-16 text-center">
                        <h3>Áskriftaleiðir</h3>
                        {/* <p>Við bjóðum upp á fyrirtækjaáskriftir - hafið samband við <a href="mailto:askrift@vb.is">askrift@vb.is</a> fyrir nánari upplýsingar.</p> */}
                    </div>
                    {!error ?
                        <>
                            {
                                filteredPlans.map(p =>
                                    <div key={p.plan_id} className={`col-lg-3 col-md-6 col-12 plan position-relative ${selectedPlan === p.plan_id ? 'active' : ''}`}>
                                        <div className="plan-inner-container h-100" onClick={() => selectPlanAndSetStep(p)}>
                                            <h2>
                                                {p.name}
                                            </h2>
                                            <div className="plan-descriptions" dangerouslySetInnerHTML={{ __html: p.description_richtext }} />
                                            <div className="amount-container position-absolute barlow">
                                                <span>{formatISK(parseFloat(p.amount))} <span className="kr">kr.</span></span>
                                                <span className="interval">{p.interval == 'month' && 'á mánuði'} {p.interval == 'year' && 'á ári'}</span>
                                            </div>
                                            <button className="btn btn-primary select-plan position-absolute semibold">Velja</button>
                                        </div>
                                    </div>
                                )
                            }
                            <div className='col-lg-3 col-md-6 col-12 plan position-relative'>
                                <div className="plan-inner-container h-100" >
                                    <a href='mailto:hopaskrift@vb.is' className='style-none'>
                                        <h2>
                                            Fyrirtækjaáskrift
                                        </h2>
                                        <div className="plan-descriptions">
                                            <p>Við bjóðum upp á hópáskriftir fyrir fyrirtæki endilega hafðu samband við okkur og við finnum góða lausn sem hentar þínu fyrirtæki.</p>
                                        </div>
                                        <a className="no-style" href="mailto:hopaskrift@vb.is">
                                            <div className="amount-container position-absolute barlow">
                                                hopaskrift@vb.is
                                            </div>
                                        </a>
                                        <div className="btn btn-primary d-flex align-items-center select-plan position-absolute semibold">Velja</div>
                                    </a>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="alert alert-danger text-center">
                                Villa kom upp við að sækja áskriftarleiðir. Vinsamlegast reyndu aftur innan skamms.
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}
