import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRealEstate } from '../../slices/realEstate';
import { formatISK } from '../../utils';

export default function RealEstateSidebar(props) {
  const dispatch = useDispatch()
  const realEstate = useSelector((state) => state.realEstate.items)

  useEffect(() => {
    dispatch(getRealEstate({type: "newest", take: "4"}))
  }, [])

  return (
      <div>
        { realEstate && realEstate.map((item) => 
        <>
        <div class="info border-bottom">
            <a href={item.url}>
                <div class="address semibold d-flex">{item.title}<div class="price semibold ms-auto">{formatISK(item.price)} kr.</div></div>
                <span class="postcode semibold d-block">{item.address.postcode.code} {item.address.postcode.placeNf}</span>
                <div class="details d-flex align-items-center">
                    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
                        <g id="Fasteignaviðbót" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Fasteignir" transform="translate(-974, -336)" fill="#009ADC" fill-rule="nonzero">
                                <g id="Group-3" transform="translate(863, 321)">
                                    <g id="Group-2" transform="translate(1.45, 10)">
                                        <path d="M114.481153,5 C115.033438,5 115.481153,5.44771525 115.481153,6 L115.481153,14.9633407 L124.55,14.9633407 C125.102285,14.9633407 125.55,15.4110559 125.55,15.9633407 L125.55,20 C125.55,20.5522847 125.102285,21 124.55,21 L110.55,21 C109.997715,21 109.55,20.5522847 109.55,20 L109.55,6 C109.55,5.44771525 109.997715,5 110.55,5 L114.481153,5 Z M114.481153,6 L110.55,6 L110.55,20 L124.55,20 L124.55,15.9633407 L114.55,15.963 L114.55,16 L112.55,16 L112.55,15 L114.481,15 L114.481,13 L112.55,13 L112.55,12 L114.481,12 L114.481,10 L112.55,10 L112.55,9 L114.481,9 L114.481153,6 Z M115.55,16 L115.55,18 L114.55,18 L114.55,16 L115.55,16 Z M118.55,16 L118.55,18 L117.55,18 L117.55,16 L118.55,16 Z M121.55,16 L121.55,18 L120.55,18 L120.55,16 L121.55,16 Z" id="Squeremeters"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span class="sqm">{item.squareMeters} m2</span>
                    <svg width="19px" height="17px" viewBox="0 0 19 17" version="1.1">
                        <g id="Fasteignaviðbót" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Fasteignir" transform="translate(-1064, -335)" fill="#009ADC" fill-rule="nonzero">
                                <g id="Group-3" transform="translate(863, 321)">
                                    <g id="Group-2" transform="translate(1.45, 10)">
                                        <path d="M213.55,4 C214.654569,4 215.55,4.8954305 215.55,6 L215.55,20 L218.55,20 L218.55,21 L199.55,21 L199.55,20 L202.55,20 L202.55,6 C202.55,4.8954305 203.445431,4 204.55,4 L213.55,4 Z M213.55,5 L204.55,5 C203.997715,5 203.55,5.44771525 203.55,6 L203.55,20 L214.55,20 L214.55,6 C214.55,5.48716416 214.16396,5.06449284 213.666621,5.00672773 L213.55,5 Z M212.55,12 C213.102285,12 213.55,12.4477153 213.55,13 C213.55,13.5522847 213.102285,14 212.55,14 C211.997715,14 211.55,13.5522847 211.55,13 C211.55,12.4477153 211.997715,12 212.55,12 Z" id="rooms"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span class="rooms">{item.rooms}</span>
                    <svg width="12px" height="15px" viewBox="0 0 12 15" version="1.1">
                        <g id="Fasteignaviðbót" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Fasteignir" transform="translate(-866, -371)" fill="#009ADC" fill-rule="nonzero">
                                <g id="Group-3" transform="translate(863, 321)">
                                    <g id="Group-2" transform="translate(1.45, 10)">
                                        <path d="M13.05,40 C13.3261424,40 13.55,40.2238576 13.55,40.5 C13.55,40.7761424 13.3261424,41 13.05,41 L12.55,41 L12.5511072,46.1182795 C13.1296799,46.4811166 13.4868834,46.9105321 13.5423995,47.3730602 L13.55,47.5 C13.55,49.368538 12.7741251,50.8093778 11.5504528,51.745975 L11.55,53.5555556 C11.55,54.2919352 10.9530463,54.8888889 10.2166667,54.8888889 L4.88333333,54.8888889 C4.14695367,54.8888889 3.55,54.2919352 3.55,53.5555556 L3.5505648,51.7467537 C2.32630505,50.8101765 1.55,49.3690559 1.55,47.5 C1.55,46.9888916 1.91810617,46.5136064 2.54988022,46.1176604 L2.55,41 L2.05,41 C1.77385763,41 1.55,40.7761424 1.55,40.5 C1.55,40.2238576 1.77385763,40 2.05,40 L13.05,40 Z M10.6613842,52.3001317 C9.75101898,52.7565576 8.68435447,52.9951073 7.55,52.9951073 C6.41564553,52.9951073 5.34898102,52.7565576 4.43861583,52.3001317 L4.43888889,53.5555556 C4.43888889,53.8010154 4.63787344,54 4.88333333,54 L10.2166667,54 C10.4621266,54 10.6611111,53.8010154 10.6611111,53.5555556 L10.6613842,52.3001317 Z M2.67201701,48.9559961 L2.72025388,49.095917 C3.38723229,50.9077551 5.31261355,52 7.55,52 C9.83515875,52 11.7359881,50.8606142 12.3865423,48.9788783 C11.2954602,49.5982259 9.53533734,50 7.55,50 C5.65823186,50 3.97094968,49.6352048 2.87123453,49.0652315 L2.67201701,48.9559961 Z M7.55,46 C4.7362915,46 2.55,46.9109548 2.55,47.5 C2.55,48.0890452 4.7362915,49 7.55,49 C10.3637085,49 12.55,48.0890452 12.55,47.5 C12.55,46.9109548 10.3637085,46 7.55,46 Z M11.64,41 L3.459,41 L3.45860818,45.6713702 C4.5304095,45.2548597 5.96877393,45 7.55,45 C9.13122607,45 10.5695905,45.2548597 11.6413918,45.6713702 L11.64,41 Z M7.55,42 C8.10228475,42 8.55,42.4477153 8.55,43 C8.55,43.5522847 8.10228475,44 7.55,44 C6.99771525,44 6.55,43.5522847 6.55,43 C6.55,42.4477153 6.99771525,42 7.55,42 Z" id="WC"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span class="bathrooms">{item.bathrooms}</span>
                    <svg width="23px" height="15px" viewBox="0 0 23 15" version="1.1">
                        <g id="Fasteignaviðbót" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Fasteignir" transform="translate(-1149, -337)" fill="#009ADC" fill-rule="nonzero">
                                <g id="Group-3" transform="translate(863, 321)">
                                    <g id="Group-2" transform="translate(1.45, 10)">
                                        <path d="M285.55,6 L285.55,15 L292.05,15 L292.05,9.5 L303.55,9.5 C305.482997,9.5 307.05,11.0670034 307.05,13 L307.05,21 L306.05,21 L306.05,16 L285.55,16 L285.55,21 L284.55,21 L284.55,6 L285.55,6 Z M303.55,10.5 L293.05,10.5 L293.05,15 L306.05,15 L306.05,13 C306.05,11.6192881 304.930712,10.5 303.55,10.5 Z M289.05,9 C290.430712,9 291.55,10.1192881 291.55,11.5 C291.55,12.8807119 290.430712,14 289.05,14 C287.669288,14 286.55,12.8807119 286.55,11.5 C286.55,10.1192881 287.669288,9 289.05,9 Z M289.05,10 C288.221573,10 287.55,10.6715729 287.55,11.5 C287.55,12.3284271 288.221573,13 289.05,13 C289.878427,13 290.55,12.3284271 290.55,11.5 C290.55,10.6715729 289.878427,10 289.05,10 Z" id="Bedrooms"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span class="bedrooms">1</span>
                </div>
            </a>
        </div>
        </>
        )}
    </div>
  )
}