import React from 'react';
import ReactDOM from 'react-dom';
import RealEstateContainer from './real-estate-container';

$.fn.realEstateContainer = function () {
  return this.each(function () {
    var options = $(this).data();
    ReactDOM.render(React.createElement(RealEstateContainer, options), this);
  });
};

$(document).ready(function () {
  $('[data-real-estate]').realEstateContainer();
});
