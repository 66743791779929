import axios from "axios";
import { getCookie } from "../../utils";

const API_URL = "/payments/";


const getSubscriptionDetails = () => {
    return axios
        .get(API_URL + "subscriptions/", {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data.response;
        });
};


const getCustomerDetails = () => {
    return axios
        .get(API_URL + "customer/", {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        })
        .then((response) => {
            return response.data.response;
        });
};


const subscribe = (cardObj) => {
    /*
        cardObj = {
            "distributionName":"asdf",
            "distributionAddress":"asdf",
            "distributionZip":"sadf",
            "differentPayer":false,
            "selectedPlan":{
                "url":"http://localhost:8021/api/v1/plans/56/",
                "name":"Áskrift að Viðskiptablaðinu, Fiskifréttum og Frjálsri verslun",
                "alternative_name":null,"reference":"allt",
                "interval":"month",
                "interval_count":1,"amount":"4995.0000",
                "currency":"ISK",
                "trial_period_days":0,
                "description":"...",
                "enabled":true,
                "private":false,
                "electronic_only":false,
                "plan_id":56
            },
            "tempToken":"4087bebdb0a765ab9245f86e8f58e8f860e1edd2"
        }
    */

    return axios
        .post( API_URL + "subscribe/", cardObj, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        }, {withCredentials: true})
        .then((response) => {
            return response.data;
        });
};

const cancelSubscription = (subscription_id) => {
    return axios
        .post( API_URL + "cancel/", {id: subscription_id}, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        }, {withCredentials: true})
        .then((response) => {
            return response.data;
        });
};

const updateCard = (args) => {

    return axios
        .post( API_URL + "updatecard/", {token: args.tempToken}, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        }, {withCredentials: true})
        .then((response) => {
            return response.data;
        });
};

const updateAddress = (args) => {

    return axios
        .patch( API_URL + "customer/", args, {
            headers: {
                'X-CSRFToken': getCookie("csrftoken"),
            }
        }, {withCredentials: true})
        .then((response) => {
            return response.data;
        });
};



const subscriptionsService = {
    getSubscriptionDetails,
    getCustomerDetails,
    subscribe,
    cancelSubscription,
    updateCard,
    updateAddress
};

export default subscriptionsService;