import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import realEstateService from "../../api/real-estate";


export const getRealEstate = createAsyncThunk(
  "realEstate/get",
  async (args, thunkAPI) => {
    try {
      const response = await realEstateService.getRealEstate(args);
      //thunkAPI.dispatch(setMessage(response.data.message));
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      //thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


const initialState = {
    items: []
};


const realEstateSlice = createSlice({
    name: "realEstate",
    initialState,
    extraReducers: {
        [getRealEstate.fulfilled]: (state, action) => {
            state.items = action.payload;
        },
        [getRealEstate.rejected]: (state, action) => {
            console.log("Action: ", action)
            state.error = "Ekki tókst að sækja fasteignir";
        },
    },
});

const { reducer } = realEstateSlice;
export default reducer;
