import React, { useEffect, useState, useRef } from 'react';
import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { useDispatch, useSelector } from 'react-redux';
import { STEP_LOGIN_BUTTON, STEP_PLAN_LIST } from '../../constants';
import { subscribe } from '../../slices/subscriptions';
import { setActivePane } from '../../slices/modal';
import { createTemporaryToken, getTemporaryTokenStatus } from '../../slices/temporaryToken';
import { formatISK } from '../../utils';
const kennitala = require('kennitala')
import { getSubscriptions } from '../../slices/subscriptions'

import moment from 'moment'
import { updateUser } from '../../slices/auth';

let timer = null


export default function AlertSsn() {

    const [customerReference, setCustomerReference] = useState('')
    const dispatch = useDispatch()
    const error = useSelector((state) => state.auth.error)
    const user = useSelector((state) => state.auth.user)

    const onSubmit = () => {
        dispatch(updateUser({field: 'ssn', newValue: customerReference}))
    }

    useEffect(() => {
        if (error === null && user.profile.ssn !== '') {
            localStorage.setItem('AlertModalHasShown', true)
            dispatch(setActivePane(STEP_LOGIN_BUTTON))
        }
    }, [user])

    return (
        <>
            <div className="container new-web-modal">
                <div className="row d-flex">
                    <div className="plans-header col-16 mb-5">
                        <h2 className="mt-3">Nýr vefur</h2>
                    </div>
                    <div className="col-16 mb-5">
                        <p>Þú ert á nýjum vef Viðskiptablaðsins og Fiskifrétta. Það er engin kennitala skráð á notandann þinn og því er ekki hægt að tengja aðganginn þinn við gilda áskrift.</p>
                        <p>Ef þú telur að þú eigir að vera með virka áskrift þá getur þú uppfært notandann þinn með kennitölu greiðanda hér.</p>
                        <p>Ef þú þarft frekari aðstoð við áskriftina er velkomið að hafa samband í gegnum netfangið <a href="mailto:askrift@vb.is">askrift@vb.is</a>.</p>
                    </div>
                    <div className="row justify-content-center d-flex">
                        <form>
                            { error!== null &&
                                <div className="alert alert-danger">
                                    {error}
                                </div>
                            }
                            <div className="form-group position-relative">
                                <input value={customerReference} onChange={(e) => setCustomerReference(e.target.value)} autoComplete="kennitala" placeholder=" " type="text" className={`form-control`} aria-describedby="customerReferenceHelp" id={`customerReference`} name="customerReference" />
                                <label className="position-absolute" htmlFor={`customerReference`}>Kennitala</label>
                            </div>
                            <div className="modal-footer d-flex flex-column justify-content-center">
                                <button type="button" className="btn btn-primary" onClick={onSubmit} disabled={customerReference === ''}>
                                    Uppfæra
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            {/* {(display === 'form' || display === 'failed') &&
                <div className="container payment-modal">
                    <div className="row justify-content-center">
                        <div className="col-md-6 border info-container">
                            <div className="plans-header d-flex">
                                <div className="col-11 text-start">
                                    <span className="barlow">Valin áskriftarleið</span>
                                    <h2>{selectedPlan.name}</h2>
                                </div>
                                <div className="col-1 d-flex align-items-center">
                                    <a className="barlow" onClick={() => dispatch(setActivePane(STEP_PLAN_LIST))}>Breyta</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            { display === 'failed' &&
                                <div className="alert alert-danger mt-5" role="alert">
                                    <strong>Það kom upp villa við að skrá kortið þitt. <br />Vinsamlegast reyndu síðar eða hafðu samband við okkur ef vandamálið leysist ekki.</strong>
                                </div>
                            }

                            <form>
                                <div className="row justify-content-center d-flex">
                                    <div className="plans-header text-center">
                                        <h3>Greiðsluupplýsingar</h3>
                                    </div>
                                </div>
                                <div className="row justify-content-center d-flex">
                                    <div className="form-group position-relative">
                                        <input value={inputs.customerReference} defaultValue={defaultValues.ssn} onChange={handleInputChange} autoComplete="kennitala" ref={customerReferenceRef} placeholder=" " type="text" className={`form-control ${validationErrors.customerReference ? 'is-invalid' : ''}`} aria-describedby="customerReferenceHelp" id={`customerReference`} name="customerReference" />
                                        <label className="position-absolute" htmlFor={`customerReference`}>Kennitala</label>
                                        {validationErrors.customerReference &&
                                            <div className="invalid-feedback">
                                                {validationErrors.customerReference}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="row justify-content-center d-flex">
                                    <div className="form-group position-relative">
                                        <input defaultValue={defaultValues.name} autoComplete="cc-name" placeholder=" " ref={cardHolderNameRef} type="text" className={`form-control ${validationErrors.cardHolderName ? 'is-invalid' : ''}`} aria-describedby="cardHolderNameHelp" id={`cardHolderName`} />
                                        <label className="position-absolute" htmlFor={`cardHolderName`}>Korthafi</label>
                                        {validationErrors.cardHolderName &&
                                            <div className="invalid-feedback">
                                                {validationErrors.cardHolderName}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="row justify-content-center d-flex">
                                    <div className="form-group position-relative ">
                                        <svg className="position-absolute" {...getCardImageProps({ images })} />
                                        <input {...cardNumberProps} value={cardNumber} placeholder=" " className={`form-control ${meta.isTouched && meta.erroredInputs.cardNumber ? 'is-invalid' : ''}`} />
                                        <label className="position-absolute" htmlFor="cardNumber">Kortanúmer</label>
                                        {meta.isTouched && meta.erroredInputs.cardNumber &&
                                            <div className="invalid-feedback">
                                                {meta.erroredInputs.cardNumber}
                                            </div>
                                        }
                                    </div>
                                    <div className="form-group position-relative ">
                                        <input {...expiryDateProps} value={expiryDate} placeholder=" " className={`form-control ${meta.isTouched && meta.erroredInputs.expiryDate ? 'is-invalid' : ''}`} />
                                        <label className="position-absolute" htmlFor="expiryDate">Gildistími</label>
                                        {meta.isTouched && meta.erroredInputs.expiryDate &&
                                            <div className="invalid-feedback">
                                                {meta.erroredInputs.expiryDate}
                                            </div>
                                        }
                                    </div>
                                    <div className="form-group position-relative ">
                                        <input {...CVCProps} value={cvc} placeholder=" " className={`form-control ${meta.isTouched && meta.erroredInputs.cvc ? 'is-invalid' : ''}`} />
                                        <label className="position-absolute" htmlFor="cvc">CVC</label>
                                        {meta.isTouched && meta.erroredInputs.cvc &&
                                            <div className="invalid-feedback">
                                                {meta.erroredInputs.cvc}
                                            </div>
                                        }
                                    </div>
                                </div>
                                {!selectedPlan.electronic_only &&
                                    <>
                                       <div className="row justify-content-center d-flex mt-5">
                                            <div className="plans-header text-center">
                                                <h3>Upplýsingar um viðtakanda</h3>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center d-flex">
                                            <div className="form-group position-relative ">
                                                <input value={inputs.distributionName || ''} placeholder=" " onChange={handleInputChange} ref={distributionNameRef} type="text" className={`form-control ${validationErrors.distributionName ? 'is-invalid' : ''}`} id={`distributionName`} name={`distributionName`} />
                                                <label className="position-absolute" htmlFor="distributionName">Nafn viðtakanda</label>
                                                {validationErrors.distributionName &&
                                                    <div className="invalid-feedback">
                                                        {validationErrors.distributionName}
                                                    </div>
                                                }
                                            </div>
                                            <div className="form-group position-relative ">
                                                <input value={inputs.distributionAddress || ''} onChange={handleInputChange} placeholder=" " ref={distributionAddressRef} type="text" className={`form-control ${validationErrors.distributionAddress ? 'is-invalid' : ''}`} id={`distributionAddress`} name={`distributionAddress`} />
                                                <label className="position-absolute" htmlFor="distributionAddress">Heimilisfang viðtakanda</label>
                                                {validationErrors.distributionAddress &&
                                                    <div className="invalid-feedback">
                                                        {validationErrors.distributionAddress}
                                                    </div>
                                                }
                                            </div>
                                            <div className="form-group position-relative ">
                                                <input value={inputs.distributionZip || ''} onChange={handleInputChange} placeholder=" " ref={distributionZipRef} type="text" className={`form-control ${validationErrors.distributionZip ? 'is-invalid' : ''}`} id={`distributionZip`} name={`distributionZip`} />
                                                <label className="position-absolute" htmlFor="distributionZip">Póstnúmer</label>
                                                {validationErrors.distributionZip &&
                                                    <div className="invalid-feedback">
                                                        {validationErrors.distributionZip}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {!differentPayer ?
                                            <a onClick={() => setDifferentPayer(true)} >Velja annan greiðanda</a>
                                            :
                                            <a onClick={() => setDifferentPayer(false)} >Hætta við</a>
                                        }
                                    </>
                                }
                                {differentPayer &&
                                    <>
                                        <div className="row justify-content-center d-flex mt-5">
                                            <div className="plans-header text-center">
                                                <h3>Upplýsingar um greiðanda</h3>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center d-flex">
                                            <div className="form-group position-relative ">
                                                <input value={inputs.payerReference || ''} onChange={handleInputChange} placeholder=" " ref={payerReferenceRef} type="text" className={`form-control ${validationErrors.payerReference ? 'is-invalid' : ''}`} id={`payerReference`} name={`payerReference`} />
                                                <label className="position-absolute" htmlFor="payerReference">Kennitala greiðanda</label>
                                                {validationErrors.payerReference &&
                                                    <div className="invalid-feedback">
                                                        {validationErrors.payerReference}
                                                    </div>
                                                }
                                            </div>
                                            <div className="form-group position-relative ">
                                                <input value={inputs.payerName || ''} onChange={handleInputChange} placeholder=" " ref={payerNameRef} type="text" className={`form-control ${validationErrors.payerName ? 'is-invalid' : ''}`} id={`payerName`} name={`payerName`} />
                                                <label className="position-absolute" htmlFor="payerName">Nafn greiðanda</label>
                                                {validationErrors.payerName &&
                                                    <div className="invalid-feedback">
                                                        {validationErrors.payerName}
                                                    </div>
                                                }
                                            </div>
                                            <div className="form-group position-relative ">
                                                <input value={inputs.payerAddress || ''} onChange={handleInputChange} placeholder=" " ref={payerAddressRef} type="text" className={`form-control ${validationErrors.payerAddress ? 'is-invalid' : ''}`} id={`payerAddress`} name={`payerAddress`} />
                                                <label className="position-absolute" htmlFor="payerAddress">Heimilisfang viðtakanda</label>
                                                {validationErrors.payerAddress &&
                                                    <div className="invalid-feedback">
                                                        {validationErrors.payerAddress}
                                                    </div>
                                                }
                                            </div>
                                            <div className="form-group position-relative ">
                                                <input value={inputs.payerZip || ''} onChange={handleInputChange} placeholder=" " ref={payerZipRef} type="text" className={`form-control ${validationErrors.payerZip ? 'is-invalid' : ''}`} id={`payerZip`} name={`payerZip`} />
                                                <label className="position-absolute" htmlFor="payerZip">Póstnúmer</label>
                                                {validationErrors.payerZip &&
                                                    <div className="invalid-feedback">
                                                        {validationErrors.payerZip}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="modal-footer d-flex flex-column justify-content-center">
                                    <button type="button" className="btn btn-primary" onClick={onSubmit}>
                                        Greiða
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
            {display === 'iframe' &&
                <div className="container">
                    <div className="row justify-content-center d-flex">
                        <div className="plans-header text-center">
                            <iframe src={cardVerificationUrl} />
                        </div>
                    </div>
                </div>
            } */}

        </>
    )
}
