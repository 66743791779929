import React from 'react'
import { Provider } from 'react-redux'
import RealEstate from './real-estate'
import { store } from '../../store'

export default function RealEstateContainer(props) {
  
    return (
        <Provider store={store}>
            <RealEstate {...props} />
        </Provider>
    )
  }