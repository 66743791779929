import React from 'react';
import ReactDOM from 'react-dom';
import { SubscriptionTeaseCounter } from './subscription-tease-counter';

function InitSubscriptionTeaseCounter(element) {
  var options = element.dataset;
  ReactDOM.render(React.createElement(SubscriptionTeaseCounter, options), element);

}

document.addEventListener("DOMContentLoaded", function() {
  var elements = document.querySelectorAll('[data-subscription-teaser]');
  elements.forEach(InitSubscriptionTeaseCounter);
});