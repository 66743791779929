import axios from "axios";

const API_URL = "https://askell.is/api";


const createTemporaryToken = (cardObj, planId=null) => {
    let expiry = cardObj.expiryDate.split("/")
    let data = {
        'card_number': cardObj.cardNumber.replaceAll(" ", ""),
        'expiration_year': expiry[1].trim(),
        'expiration_month': expiry[0].trim(),
        'cvv_number': cardObj['cvc'],
    }
    if (planId) {
        data.plan = planId
    }
    return axios
        .post(API_URL + "/temporarypaymentmethod/", data, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Api-Key ${window.ASKELL_PUBLIC_KEY}`,
            }
        })
        .then((response) => {
            return response.data;
        });
};

const getTemporaryTokenStatus = (token) => {
    
    return axios
        .get(`${API_URL}/temporarypaymentmethod/${token}/`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Api-Key ${window.ASKELL_PUBLIC_KEY}`,
            }
        })
        .then((response) => {
            return response.data;
        });
};





const askellService = {
    createTemporaryToken,
    getTemporaryTokenStatus,
};

export default askellService;