import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { STEP_CHECK_LOGIN, STEP_LOGIN, STEP_LOGIN_BUTTON, STEP_PAYMENT_INFORMATION, STEP_PLAN_LIST, STEP_REGISTER, STEP_MANAGE, STEP_ALERT_SSN } from '../../constants'
import CheckLogin from '../check-login'
import Login from '../login'
import LoginButton from '../login-button'
import ModalClose from '../modal-close'
import PaymentInformation from '../payment-information'
import PlanList from '../plan-list'
import Register from '../register'
import { setActivePane } from '../../slices/modal'
import { checkLogin } from '../../slices/auth'
import ManageSubscription from '../manage-subscription'
import AlertSsn from '../alert-ssn'

export default function LoginWrapper(props) {
    const dispatch = useDispatch()
    const activePane = useSelector((state) => state.modal.activePane)
    const user = useSelector((state) => state.auth.user)
    const [clickOriginHash, setClickOriginHash] = useState(false)

    let ActiveComponent = LoginButton

    let modalClasses = ''

    $('body').removeClass('position-fixed w-100');

    useEffect(() => {
        const checkState = () => {
            if (window.location.hash === "#kaupa" && !clickOriginHash) {
                setClickOriginHash(true)
                dispatch(setActivePane(STEP_PLAN_LIST))
            }
            if (window.location.hash === "#innskraning" && !clickOriginHash) {
                setClickOriginHash(true)
                dispatch(setActivePane(STEP_LOGIN))
            }

        }
        window.addEventListener('popstate', (event) => checkState())
        checkState()

        const AlertModalHasShown = (localStorage.getItem('AlertModalHasShown') === 'true') || false
        if (user && user.authenticated && user.profile && user.profile.ssn === "" && !AlertModalHasShown) {
            dispatch(setActivePane(STEP_ALERT_SSN))
            localStorage.setItem('AlertModalHasShown', true)
        }
    });
    

    useEffect(() =>  {
        dispatch(checkLogin())
    }, [])

    switch(activePane) {
        case STEP_LOGIN_BUTTON:
            return <LoginButton {...props} />;
        case STEP_PLAN_LIST:
            ActiveComponent = PlanList;
            break;
        case STEP_CHECK_LOGIN:
            ActiveComponent = CheckLogin;
            break;
        case STEP_LOGIN:
            ActiveComponent = Login;
            break;
        case STEP_REGISTER:
            ActiveComponent = Register;
            break;
        case STEP_PAYMENT_INFORMATION:
            ActiveComponent = PaymentInformation;
            break;
        case STEP_MANAGE:
            if (user && user.authenticated && user.profile && user.profile.ssn === "") {
                modalClasses = 'modal-small'
                ActiveComponent = AlertSsn;   
            } else {
                ActiveComponent = ManageSubscription;
            }
            break;
        case STEP_ALERT_SSN:
            modalClasses = 'modal-small'
            ActiveComponent = AlertSsn;
                break;
        default:
            return <LoginButton {...props} />;
    }

    $('body').addClass('position-fixed w-100');

    return (
        <div className={`login-modal ${modalClasses}`}>
            <ModalClose />
            <div className="container">
                <div className="logo-container row justify-content-center d-flex">
                    <span className="text-center"><img src="https://vb.overcastcdn.com/img/Vb-logo.3390ef9d1736.svg" /></span>
                </div>
            </div>
            <ActiveComponent startingStep={props.startingStep} />
        </div>
    )
}

LoginWrapper.defaultProps = {
    startingStep: STEP_REGISTER,
    buttonText: 'Innskráning'
}