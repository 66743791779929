import React from 'react';
import ReactDOM from 'react-dom';
import LoginContainer from './login-container';
import LoginButtonContainer from './login-button';


$.fn.loginContainer = function () {
  return this.each(function () {
    var options = $(this).data();
    ReactDOM.render(React.createElement(LoginContainer, options), this);
  });
};

$(document).ready(function () {
  $('[data-login-container]').loginContainer();

});



$.fn.loginButton = function () {
  return this.each(function () {
    var options = $(this).data();
    ReactDOM.render(React.createElement(LoginButtonContainer, options), this);
  });
};

$(document).ready(function () {
  $('[data-login-button]').loginButton();

});